<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <div class="form">
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">
              {{ $t("Nation Information") }}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">
              {{ $t("Change nation's general information") }}
            </span>
          </div>
          <div class="card-toolbar">
            <button class="btn btn-success mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click.prevent="handleSubmit(save)">
              {{ $t("Save Changes") }}
            </button>
          </div>
        </div>
        <div class="card-body detail">
          <b-row class="mb-10">
            <b-col cols="3">{{ $t('Area') }}</b-col>
            <b-col class="ml-3">{{ nation.area }}</b-col>
          </b-row>
          <b-row class="my-10">
            <b-col cols="3">{{ $t('Continent') }}</b-col>
            <b-col class="ml-3">{{ nation.continent }}</b-col>
          </b-row>
          <b-row class="my-10">
            <b-col cols="3">{{ $t('Capital') }}</b-col>
            <b-col class="ml-3">{{ nation.capital }}</b-col>
          </b-row>
          <b-row class="my-10">
            <b-col cols="3">{{ $t('Population') }}</b-col>
            <b-col class="ml-3">{{ nation.population }}</b-col>
          </b-row>
          <b-row class="my-10">
            <b-col cols="3">{{ $t('Phone prefix') }}</b-col>
            <b-col class="ml-3">{{ nation.phone }}</b-col>
          </b-row>
          <b-row class="my-10">
            <b-col cols="3">{{ $t('Postal code format') }}</b-col>
            <b-col class="ml-3">{{ nation.postal_code_format }}</b-col>
          </b-row>
          <b-row class="my-10">
            <b-col cols="3">{{ $t('Tld') }}</b-col>
            <b-col class="ml-3">{{ nation.tld }}</b-col>
          </b-row>
          <validation-provider v-slot="{ errors }" :name="$t('Citizenship')" vid="citizenship" rules="required" tag="div">
            <euro-radio v-model="form.citizenship_with_passport" :options="OPTIONS"
              :label="`${$t('Citizenship with passport')}`" horizontal :error-messages="errors" size="lg"></euro-radio>
          </validation-provider>
          <validation-provider v-slot="{ errors }" :name="$t('Date start')" vid="date_start" rules="max:30|required">
            <euro-input v-model="form.fiscal_year.start" :placeholder="`${$t('DD-MM')}`"
              :label="`${$t('Fiscal year start')}`" horizontal :error-messages="errors"></euro-input>
          </validation-provider>
          <validation-provider v-slot="{ errors }" :name="$t('Date end')" vid="date_end" rules="max:30|required">
            <euro-input v-model="form.fiscal_year.end" :label="`${$t('Fiscal year end')}`" horizontal
              :error-messages="errors"></euro-input>
          </validation-provider>

        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import NationsCountryService from "@/core/services/nation/nations-country.service";
import { getValidationErrors, successToast } from "@/core/helpers";
import { backendErrorSwal, unsavedChangesSwal } from "@/core/helpers/swal";
import _ from "lodash";
export default {
  components: {
  },
  props: {
    nation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      OPTIONS: [],
      isSaving: false,
      form: {
        citizenship_with_passport: null,
        fiscal_year: {
          start: "",
          end: "",
        }
      },
      originalData: null,
    }
  },
  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;

        const newErrors = getValidationErrors(val, this.legalPersonForm);

        formObs.setErrors({ ...newErrors });
      },
    },
  },
  async created() {
    this.OPTIONS = [{
      value: true,
      text: "Yes"
    },
    {
      value: false,
      text: "No"
    }],
      this.fillForm();
  },

  methods: {
    fillForm() {
      this.form = {
        citizenship_with_passport: this.nation.citizenship_with_passport,
        fiscal_year: {
          end: this.nation.fiscal_year.end,
          start: this.nation.fiscal_year.start
        }
      }
      this.originalData = _.cloneDeep(this.form);
    },
    async save() {
      this.isSaving = true;
      const body = { ...this.form, };
      try {
        await NationsCountryService.update(this.nation.iso, body);
        this.originalData = _.cloneDeep(this.form);
        successToast(this.$t("Nation information saved"));
        this.$emit("updated");
      } catch (err) {
        console.log(err?.response?.status);
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail ?? this.$t("Failed to save nation information"));
        }
      } finally {
        this.isSaving = false;
      }
    },
    cancel() {
      this.fillForm();
    },
    async beforeRouteLeave(__, ___, next) {
      if (!_.isEqual(this.form, this.originalData)) {
        const res = await unsavedChangesSwal();
        if (res.isConfirmed) await this.save();
        if (res.isDismissed) return;
      }
      next();
    },
  }
};
</script>
