var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "card card-custom"
        }, [_c('div', {
          staticClass: "card-header border-0 pt-6 pb-0"
        }, [_c('div', {
          staticClass: "card-title align-items-start flex-column"
        }, [_c('h3', {
          staticClass: "card-label font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t("Nation Information")) + " ")]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(" " + _vm._s(_vm.$t("Change nation's general information")) + " ")])]), _c('div', {
          staticClass: "card-toolbar"
        }, [_c('button', {
          staticClass: "btn btn-success mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return handleSubmit(_vm.save);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")])])]), _c('div', {
          staticClass: "card-body detail"
        }, [_c('b-row', {
          staticClass: "mb-10"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('Area')))]), _c('b-col', {
          staticClass: "ml-3"
        }, [_vm._v(_vm._s(_vm.nation.area))])], 1), _c('b-row', {
          staticClass: "my-10"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('Continent')))]), _c('b-col', {
          staticClass: "ml-3"
        }, [_vm._v(_vm._s(_vm.nation.continent))])], 1), _c('b-row', {
          staticClass: "my-10"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('Capital')))]), _c('b-col', {
          staticClass: "ml-3"
        }, [_vm._v(_vm._s(_vm.nation.capital))])], 1), _c('b-row', {
          staticClass: "my-10"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('Population')))]), _c('b-col', {
          staticClass: "ml-3"
        }, [_vm._v(_vm._s(_vm.nation.population))])], 1), _c('b-row', {
          staticClass: "my-10"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('Phone prefix')))]), _c('b-col', {
          staticClass: "ml-3"
        }, [_vm._v(_vm._s(_vm.nation.phone))])], 1), _c('b-row', {
          staticClass: "my-10"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('Postal code format')))]), _c('b-col', {
          staticClass: "ml-3"
        }, [_vm._v(_vm._s(_vm.nation.postal_code_format))])], 1), _c('b-row', {
          staticClass: "my-10"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('Tld')))]), _c('b-col', {
          staticClass: "ml-3"
        }, [_vm._v(_vm._s(_vm.nation.tld))])], 1), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Citizenship'),
            "vid": "citizenship",
            "rules": "required",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('euro-radio', {
                attrs: {
                  "options": _vm.OPTIONS,
                  "label": "".concat(_vm.$t('Citizenship with passport')),
                  "horizontal": "",
                  "error-messages": errors,
                  "size": "lg"
                },
                model: {
                  value: _vm.form.citizenship_with_passport,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "citizenship_with_passport", $$v);
                  },
                  expression: "form.citizenship_with_passport"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Date start'),
            "vid": "date_start",
            "rules": "max:30|required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('euro-input', {
                attrs: {
                  "placeholder": "".concat(_vm.$t('DD-MM')),
                  "label": "".concat(_vm.$t('Fiscal year start')),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.fiscal_year.start,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.fiscal_year, "start", $$v);
                  },
                  expression: "form.fiscal_year.start"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Date end'),
            "vid": "date_end",
            "rules": "max:30|required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Fiscal year end')),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.fiscal_year.end,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.fiscal_year, "end", $$v);
                  },
                  expression: "form.fiscal_year.end"
                }
              })];
            }
          }], null, true)
        })], 1)])])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }